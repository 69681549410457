@media print {
    :root {
        --unit-xxs: .25rem;
        --unit-xs: .5rem;
        --unit-sm: .75rem;
        --unit-md: 1rem;
        --unit-lg: 1.5rem;
        --unit-xl: 2rem;

        --default-font-size: .75rem;
        
    }

    /* force chrome to print colour backgrounds */
    /* body { -webkit-print-color-adjust: exact !important; } */

    /* stop block elements from splitting across pages */
    div { display: table; }
    .container div{ break-inside: avoid; }

    /* hide from printer */
    .no-print { display: none !important;}


}
