/* SA theme */

@import './app/agenda-sa-2024.css';


[data-theme='sa-2024'] {

    /* colour */

    --primary: #794DF6;
    --secondary: #3f058b;
    --accent: aqua;

    --bg-global: #3f058b;

    --default-text-color: rgb(255, 255, 255);
    --default-heading-color: var(--accent);

    --wash: #F9F9FA;
    --light: #E4E1EA;
    --medium: #6D687C;
    --dark: #31136B;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);

    --default-radius: 1rem;

    --standard-field-radius: .5rem;

}


.bg-glass {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.25) !important;
}
.bg-glass-dark {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.bg-glass-clear {
    backdrop-filter: blur(10px);
}


[data-theme='sa-2024'] .c-primary { color: var(--primary); }
[data-theme='sa-2024'] .c-secondary { color: var(--secondary); }
[data-theme='sa-2024'] .c-accent { color: var(--accent); }

[data-theme='sa-2024'] .c-p { color: var(--default-text-color); }
[data-theme='sa-2024'] .c-h { color: var(--default-heading-color); }

[data-theme='sa-2024'] .c-black { color: black; }
[data-theme='sa-2024'] .c-white { color: white; }

[data-theme='sa-2024'] .c-light { color: var(--light) }
[data-theme='sa-2024'] .c-medium { color: var(--medium) }




[data-theme='sa-2024'] .bg-global { background-color: var(--bg-global); }

[data-theme='sa-2024'] .bg-primary { background-color: var(--primary); }
[data-theme='sa-2024'] .bg-secondary { background-color: var(--secondary); }
[data-theme='sa-2024'] .bg-accent { background-color: var(--accent); }

[data-theme='sa-2024'] .bg-theme { 
    background: transparent !important;
}
[data-theme='sa-2024'] .bg-theme.bg-glass { 
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.25) !important;
}



[data-theme='sa-2024'] .bg-wash,
[data-theme='sa-2024'] .bg-light { 
    background: var(--accent);
    border-radius: var(--default-radius);
}
[data-theme='sa-2024'] .bg-medium,
[data-theme='sa-2024'] .bg-dark { 
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.25) !important;
    border-radius: var(--default-radius);
}



/* LAYOUT BLOCKS ––––––––––––––––––––––––––––––––––––––––––––––––– */

@media(min-width:600px){
    [data-theme='sa-2024'] .block {
        margin: var(--space-sm);
    }
}


/* BACKGROUNDS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


/* default */

[data-theme='sa-2024'] .bg-default .formatted .h,
[data-theme='sa-2024'] .bg-default .formatted h1,
[data-theme='sa-2024'] .bg-default .formatted h2,
[data-theme='sa-2024'] .bg-default .formatted h3,
[data-theme='sa-2024'] .bg-default .formatted h4,
[data-theme='sa-2024'] .bg-default .formatted h5,
[data-theme='sa-2024'] .bg-default .formatted h6 {
    color: white;
}

/* white */

[data-theme='sa-2024'] .bg-white.formatted {
	color: var(--default-text-color);
}
[data-theme='sa-2024'] .bg-white .formatted .h,
[data-theme='sa-2024'] .bg-white .formatted h1,
[data-theme='sa-2024'] .bg-white .formatted h2,
[data-theme='sa-2024'] .bg-white .formatted h3,
[data-theme='sa-2024'] .bg-white .formatted h4,
[data-theme='sa-2024'] .bg-white .formatted h5,
[data-theme='sa-2024'] .bg-white .formatted h6 {
    color: var(--accent);
}

/* wash */

[data-theme='sa-2024'] .bg-wash {
    color: var(--default-text-color);
}
[data-theme='sa-2024'] .bg-wash .formatted h1,
[data-theme='sa-2024'] .bg-wash .formatted h2,
[data-theme='sa-2024'] .bg-wash .formatted h3,
[data-theme='sa-2024'] .bg-wash .formatted h4,
[data-theme='sa-2024'] .bg-wash .formatted h5,
[data-theme='sa-2024'] .bg-wash .formatted h6 {
    color: var(--secondary);
}


/* light */

[data-theme='sa-2024'] .bg-light {
    color: var(--default-text-color);
}
[data-theme='sa-2024'] .bg-light .formatted h1,
[data-theme='sa-2024'] .bg-light .formatted h2,
[data-theme='sa-2024'] .bg-light .formatted h3,
[data-theme='sa-2024'] .bg-light .formatted h4,
[data-theme='sa-2024'] .bg-light .formatted h5,
[data-theme='sa-2024'] .bg-light .formatted h6 {
    color: var(--secondary);
}
[data-theme='sa-2024'] .bg-light .formatted {
    color: var(--secondary);
}

/* primary */


[data-theme='sa-2024'] .bg-primary .form input[type='submit'] {
    background-color: var(--dark);
    border-color: var(--dark);
    color: white;
}
[data-theme='sa-2024'] .bg-primary .form input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: var(--dark);
}


/* medium */

[data-theme='sa-2024'] .bg-medium .formatted h1,
[data-theme='sa-2024'] .bg-medium .formatted h2,
[data-theme='sa-2024'] .bg-medium .formatted h3,
[data-theme='sa-2024'] .bg-medium .formatted h4,
[data-theme='sa-2024'] .bg-medium .formatted h5,
[data-theme='sa-2024'] .bg-medium .formatted h6 {
    color: white;
}


/* theme */

[data-theme='sa-2024'] .bg-theme,
[data-theme='sa-2024'] .bg-theme .formatted h1,
[data-theme='sa-2024'] .bg-theme .formatted h2,
[data-theme='sa-2024'] .bg-theme .formatted h3,
[data-theme='sa-2024'] .bg-theme .formatted h4,
[data-theme='sa-2024'] .bg-theme .formatted h5,
[data-theme='sa-2024'] .bg-theme .formatted h6 {
    color: white;
}



/* BUTTONS ------------------------------------------------- */


[data-theme='sa-2024'] .button {
	border: 1px solid var(--primary);
	background-color: var(--primary);
	color: white;
}

[data-theme='sa-2024'] .button:hover {
	background-color: var(--accent);
	border-color: var(--accent);
	color: white;
}
[data-theme='sa-2024'] .button.inverse:hover {
	color: var(--secondary);
}

[data-theme='sa-2024'] .button.outline {
	border-color: var(--secondary);
}
[data-theme='sa-2024'] .button.outline:hover {
	background-color: var(--secondary);
	border-color: var(--secondary);
	color: white;
}

[data-theme='sa-2024'] .button.outline.inverse {
	color: white;
	background-color: transparent;
	border-color: var(--primary);
}
[data-theme='sa-2024'] .button.outline.inverse:hover {
	background-color: var(--primary);
	color: var(--secondary);
}



/* FORMS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


[data-theme='sa-2024'] .form input[type='submit'] {
	border: 1px solid var(--accent);
	background-color: var(--accent);
    color: var(--secondary);
}

[data-theme='sa-2024'] .form input[type='submit']:hover {
    border: 1px solid white;
	background-color: white;
}

[data-theme='sa-2024'] .form input[type='text'],
[data-theme='sa-2024'] .form input[type='email'] {
    border-radius: .5rem;
}

[data-theme='sa-2024'] .form input[type='checkbox'] {
    accent-color: var(--accent);
}

/* hubspot-specific */

[data-theme='sa-2024'] .hs-form-field select {
    margin-top: var(--space-xxs);
    cursor: pointer;
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    width: 100%;
    padding: .75rem 1rem;
    border: 1px var(--accent) solid !important;
    border-radius: var(--standard-field-radius) !important;
    background-color: var(--standard-field-bg) !important;

    background-image: url('/assets/ui/select-chevron-sa-2024.svg');
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem);
}

/* BORDERS ------------------------------------------------ */

.bc-primary { border-color: var(--primary);}
.bc-secondary { border-color: var(--secondary);}
.bc-accent { border-color: var(--accent);}

.underline { text-decoration: underline; cursor: pointer; }