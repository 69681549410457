/* AE theme 2022 */


[data-theme='ae-2022'] {

	--primary: #33408b;
    --secondary: #29336b;
    --accent: #a83a90;

    --bg-global: rgb(255, 255, 255);

    --default-text-color: rgb(71, 71, 71);
    --default-heading-color: var(--primary);

    --wash: #F9F9FA;
    --light: #E1E2EA;
    --medium: #827e88;
    --dark: #202B64;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);


	/* type */

    --default-font-family: 'Outfit', sans-serif;
    --default-font-weight: 300;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* spacing */

    --container-max-width: 1000rem;
    --container-adjacent-spacing-factor: 0.5;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 3rem;

    --default-bg: rgba(255, 255, 255, 0.75);


    /* header */

    --header-height: 12.5rem;
    --header-height-compact: 6.875rem;
    --header-height-mobile: 8.5rem;
    --header-height-compact-mobile: 8.5rem;


    /* component specifics */

    --headline-height: 6.5vw;
    --headline-offset: .6vw;
    
}


/* BACKGROUNDS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


/* default */

[data-theme='ae-2022'] .bg-default .h,
[data-theme='ae-2022'] .bg-default .formatted h1,
[data-theme='ae-2022'] .bg-default .formatted h2,
[data-theme='ae-2022'] .bg-default .formatted h3,
[data-theme='ae-2022'] .bg-default .formatted h4,
[data-theme='ae-2022'] .bg-default .formatted h5,
[data-theme='ae-2022'] .bg-default .formatted h6 {
    color: var(--primary-ae);
}

/* wash */

[data-theme='ae-2022'] .bg-wash {
    color: var(--default-text-color-ae);
}
[data-theme='ae-2022'] .bg-wash .formatted h1,
[data-theme='ae-2022'] .bg-wash .formatted h2,
[data-theme='ae-2022'] .bg-wash .formatted h3,
[data-theme='ae-2022'] .bg-wash .formatted h4,
[data-theme='ae-2022'] .bg-wash .formatted h5,
[data-theme='ae-2022'] .bg-wash .formatted h6 {
    color: var(--primary-ae);
}


/* light */

[data-theme='ae-2022'] .bg-light {
    color: var(--default-text-color-ae);
}
[data-theme='ae-2022'] .bg-light .formatted h1,
[data-theme='ae-2022'] .bg-light .formatted h2,
[data-theme='ae-2022'] .bg-light .formatted h3,
[data-theme='ae-2022'] .bg-light .formatted h4,
[data-theme='ae-2022'] .bg-light .formatted h5,
[data-theme='ae-2022'] .bg-light .formatted h6 {
    color: var(--primary-ae);
}

/* primary */

[data-theme='ae-2022'] .bg-primary,
[data-theme='ae-2022'] .bg-primary .formatted h1,
[data-theme='ae-2022'] .bg-primary .formatted h2,
[data-theme='ae-2022'] .bg-primary .formatted h3,
[data-theme='ae-2022'] .bg-primary .formatted h4,
[data-theme='ae-2022'] .bg-primary .formatted h5,
[data-theme='ae-2022'] .bg-primary .formatted h6 {
    color: white;
}

[data-theme='ae-2022'] .bg-primary .form input[type='submit'] {
    background-color: var(--dark-ae);
    border-color: var(--dark-ae);
    color: white;
}
[data-theme='ae-2022'] .bg-primary .form input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: var(--dark-ae);
}


/* medium */

[data-theme='ae-2022'] .bg-medium .formatted h1,
[data-theme='ae-2022'] .bg-medium .formatted h2,
[data-theme='ae-2022'] .bg-medium .formatted h3,
[data-theme='ae-2022'] .bg-medium .formatted h4,
[data-theme='ae-2022'] .bg-medium .formatted h5,
[data-theme='ae-2022'] .bg-medium .formatted h6 {
    color: var(--primary-ae);
}


/* theme */

[data-theme='ae-2022'] .bg-theme,
[data-theme='ae-2022'] .bg-theme .formatted h1,
[data-theme='ae-2022'] .bg-theme .formatted h2,
[data-theme='ae-2022'] .bg-theme .formatted h3,
[data-theme='ae-2022'] .bg-theme .formatted h4,
[data-theme='ae-2022'] .bg-theme .formatted h5,
[data-theme='ae-2022'] .bg-theme .formatted h6 {
    color: white;
}

[data-theme='ae-2022'] .bg-theme .bg-white,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h1,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h2,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h3,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h4,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h5,
[data-theme='ae-2022'] .bg-theme .bg-white .formatted h6 {
    color: var(--default-text-color-ae);
}


/* BUTTONS ------------------------------------------------- */


[data-theme='ae-2022'] .button {
	border: 1px solid var(--secondary-ae);
	background-color: var(--secondary-ae);
	color: white;
}

[data-theme='ae-2022'] .button:hover {
	background-color: var(--primary-ae);
	border-color: var(--primary-ae);
	color: white;
}
[data-theme='ae-2022'] .button.inverse:hover {
	color: var(--secondary-ae);
}

[data-theme='ae-2022'] .button.outline {
	border-color: var(--secondary-ae);
}
[data-theme='ae-2022'] .button.outline:hover {
	background-color: var(--secondary-ae);
	border-color: var(--secondary-ae);
	color: white;
}

[data-theme='ae-2022'] .button.outline.inverse {
	color: white;
	background-color: transparent;
	border-color: var(--primary-ae);
}
[data-theme='ae-2022'] .button.outline.inverse:hover {
	background-color: var(--primary-ae);
	color: var(--secondary-ae);
}







/* FORMS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


[data-theme='ae-2022'] .form input[type='submit'] {
	border: 1px solid var(--secondary-ae);
	background-color: var(--secondary-ae);
}

[data-theme='ae-2022'] .form input[type='submit']:hover {
    border: 1px solid var(--accent-ae);
	background-color: var(--accent-ae);
}
