/* * { background-color: rgba(0, 255, 255, 0.144); } */

@import './decal.config.css';
@import './decal.config-sa.css';
@import './decal.config-ae.css';

@import './decal/decal.css';
@import './decal.fonts.css';

@import './decal.theme.css';
@import './decal.theme-sa.css';
@import './decal.theme-sa-2023.css';
@import './decal.theme-ae-2022.css';
@import './decal.theme-ae-2023.css';
@import './decal.theme-eu-2023.css';
@import './decal.theme-eu-2024.css';
@import './decal.theme-sa-2024.css';
@import './decal.theme-sa-2025.css';
@import './decal.theme-world-2024.css';

@import './decal.theme-global-2024.css';

@import './app/components/underline-link.css';
@import './app/components/button.css';
@import './app/components/button-grid.css';

@import './app/forms/forms.css';

@import './decal/global/print.css';




/* global overrides */

.fw-600.fs-5.c-accent:not(:first-child) {
    margin-top: 2rem;
}