/* GLOBAL theme */

.ff-space { font-family: 'Space Grotesk' !important;}

.global .c-primary { color: var(--primary); }
.global .c-secondary { color: var(--secondary); }
.global .c-accent { color: var(--accent); }

.global .c-p { color: var(--default-text-color); }
.global .c-h { color: var(--default-heading-color); }

.global .c-black { color: black; }
.global .c-white { color: white; }

.global .c-light { color: var(--light) }
.global .c-medium { color: var(--medium) }




.global .bg-global { background-color: var(--bg-global); }

.global .bg-primary { background-color: var(--primary); }
.global .bg-secondary { background-color: var(--secondary); }
.global .bg-accent { background-color: var(--accent); }

.global .bg-theme { 
    background: var(--primary, --primary);
    background: linear-gradient(90deg, rgb(10, 64, 82) 0%, rgb(64, 107, 138) 73%);
}

.global .bg-wash { background-color: var(--wash);}
.global .bg-light { background-color: var(--light);}
.global .bg-medium { background-color: var(--medium);}
.global .bg-dark { background-color: var(--dark);}



.global .formatted h1,
.global .formatted h2,
.global .formatted h3,
.global .formatted h4,
.global .formatted h5,
.global .formatted h6 {
    color: var(--accent);
}
.global {
    color: white;
}

/* * { background-color: rgba(0, 255, 255, 0.258);} */


.global .highlight span {
    color: white;
}

.global .swiper-button-prev::after,
.global .swiper-button-next::after {
    color: white;
    font-size: 2rem !important;
}
.global .swiper-button-prev,
.global .swiper-button-next {
    height: 1rem !important;
}

@media (max-width: 900px) {
    .global .swiper-button-prev::after,
    .global .swiper-button-next::after {
        font-size: 1rem !important;
    }
    .global .swiper-button-prev,
    .global .swiper-button-next {
        height: 1rem !important;
    }
    .global .swiper-button-prev {
        left: 0 !important;
    }
    .global .swiper-button-next {
        right: 0 !important;
    }
}


/* default */

.global .bg-default {
    color: var(--default-text-color);
}
.global .bg-default .formatted h1,
.global .bg-default .formatted h2,
.global .bg-default .formatted h3,
.global .bg-default .formatted h4,
.global .bg-default .formatted h5,
.global .bg-default .formatted h6 {
    color: var(--primary);
}

/* wash */

.global .bg-wash {
    color: var(--default-text-color);
}
.global .bg-wash .formatted h1,
.global .bg-wash .formatted h2,
.global .bg-wash .formatted h3,
.global .bg-wash .formatted h4,
.global .bg-wash .formatted h5,
.global .bg-wash .formatted h6 {
    color: var(--primary);
}


/* light */

.global .bg-light {
    color: var(--default-text-color);
}
.global .bg-light .formatted h1,
.global .bg-light .formatted h2,
.global .bg-light .formatted h3,
.global .bg-light .formatted h4,
.global .bg-light .formatted h5,
.global .bg-light .formatted h6 {
    color: var(--primary);
}

/* primary */

.global .bg-primary {
    color: white;
}

.global .bg-primary .formatted h1,
.global .bg-primary .formatted h2,
.global .bg-primary .formatted h3,
.global .bg-primary .formatted h4,
.global .bg-primary .formatted h5,
.global .bg-primary .formatted h6 {
    color: white;
}

.global .bg-primary .form label {
    color: white;
}
.global .bg-primary .form input[type='submit'] {
    background-color: var(--dark);
    border-color: var(--dark);
    color: white;
}
.global .bg-primary .form input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: var(--dark);
}

.global .bg-secondary {
    color: white;
}


/* medium */

.global .bg-medium,
.global .bg-medium a {
    color: white;
}
.global .bg-medium .formatted h1,
.global .bg-medium .formatted h2,
.global .bg-medium .formatted h3,
.global .bg-medium .formatted h4,
.global .bg-medium .formatted h5,
.global .bg-medium .formatted h6 {
    color: var(--primary);
}


/* dark */

.global .bg-dark {
    color: white
}
.global .bg-dark .formatted h1,
.global .bg-dark .formatted h2,
.global .bg-dark .formatted h3,
.global .bg-dark .formatted h4,
.global .bg-dark .formatted h5,
.global .bg-dark .formatted h6 {
    color: white;
}


/* theme */

.global .bg-theme {
    color: white;
}
.global .bg-theme .formatted h1,
.global .bg-theme .formatted h2,
.global .bg-theme .formatted h3,
.global .bg-theme .formatted h4,
.global .bg-theme .formatted h5,
.global .bg-theme .formatted h6 {
    color: white;
}