/* AE theme 2023 */


[data-theme='ae-2023'] {

	--primary: #4B433F;
	--secondary: #A09488;
	--accent: #A09488;
	--dark: #A09488;

	--default-text-color: #4B433F;
	--default-heading-color: var(--default-text-color);

    --wash: #fafaf9;
    --light: #ececec;
    --medium: #827e88;
    --dark: #4B433F;

    --error: rgb(255, 68, 0);
    --success: rgb(121, 173, 0);
    --warning: rgb(255, 166, 0);


	/* type */

    --default-font-family: 'Outfit', sans-serif;
    --default-font-weight: 300;
    --default-font-size: 1rem;
    --default-line-height: 1.4;

    --default-heading-line-height: 1.1;
    --default-heading-weight: 600;


    /* spacing */

    --container-max-width: 1000rem;
    --container-adjacent-spacing-factor: 0.5;

    --unit-xxs: .5rem;
    --unit-xs: 1rem;
    --unit-sm: 2rem;
    --unit-md: 4rem;
    --unit-lg: 6rem;
    --unit-xl: 8rem;


    /* global UI styling */

    --default-radius: 0;
    --default-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    --default-shadow-size: 20px;
    --default-shadow-opacity: 0.2;

    --default-button-radius: 3rem;

    --default-bg: rgba(255, 255, 255, 0.75);


    /* header */

    --header-height: 12.5rem;
    --header-height-compact: 6.875rem;
    --header-height-mobile: 8.5rem;
    --header-height-compact-mobile: 8.5rem;


    /* component specifics */

    --headline-height: 6.5vw;
    --headline-offset: .6vw;
    
}

@media(max-width:600px){
    :root {
        /* component specifics */

        --headline-height: 8.5vw;
        --headline-offset: .85vw;
    }
}




[data-theme='ae-2023'] .container {
	position: relative;
	z-index: 2;
}

[data-theme='ae-2023'] .c-primary { color: var(--primary); }
[data-theme='ae-2023'] .c-secondary { color: var(--secondary); }
[data-theme='ae-2023'] .c-accent { color: var(--accent); }

[data-theme='ae-2023'] .c-p { color: var(--default-text-color); }
[data-theme='ae-2023'] .c-h { color: var(--default-heading-color); }

[data-theme='ae-2023'] .c-black { color: black; }
[data-theme='ae-2023'] .c-white { color: white; }

[data-theme='ae-2023'] .c-light { color: var(--light) }
[data-theme='ae-2023'] .c-medium { color: var(--medium) }



[data-theme='ae-2023'] .bg-default { background-color: var(--default-bg, white); }

[data-theme='ae-2023'] .bg-primary { background-color: var(--primary); }
[data-theme='ae-2023'] .bg-secondary { background-color: var(--secondary); }
[data-theme='ae-2023'] .bg-accent { background-color: var(--accent); }

[data-theme='ae-2023'] .bg-theme { 
    background: white;
	background-image: url('/assets/theme/ae-2023/kv-pinstripes.png');
	background-size: 100%;
}
[data-theme='ae-2023'] .bg-theme.bg-glass { 
    background: var(--secondary);
}

[data-theme='ae-2023'] .bg-wash { background-color: var(--wash);}
[data-theme='ae-2023'] .bg-light { background-color: var(--light);}
[data-theme='ae-2023'] .bg-medium { background-color: var(--medium);}
[data-theme='ae-2023'] .bg-dark { background-color: var(--dark);}



[data-theme='ae-2023'] .bc-primary { border-color: var(--primary);}
[data-theme='ae-2023'] .bc-secondary { border-color: var(--secondary);}
[data-theme='ae-2023'] .bc-accent { border-color: var(--accent);}



/* BACKGROUNDS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


/* default */

[data-theme='ae-2023'] .bg-default .h,
[data-theme='ae-2023'] .bg-default .formatted h1,
[data-theme='ae-2023'] .bg-default .formatted h2,
[data-theme='ae-2023'] .bg-default .formatted h3,
[data-theme='ae-2023'] .bg-default .formatted h4,
[data-theme='ae-2023'] .bg-default .formatted h5,
[data-theme='ae-2023'] .bg-default .formatted h6 {
    color: var(--primary);
}

/* wash */

[data-theme='ae-2023'] .bg-wash {
    color: var(--default-text-color);
}
[data-theme='ae-2023'] .bg-wash .formatted h1,
[data-theme='ae-2023'] .bg-wash .formatted h2,
[data-theme='ae-2023'] .bg-wash .formatted h3,
[data-theme='ae-2023'] .bg-wash .formatted h4,
[data-theme='ae-2023'] .bg-wash .formatted h5,
[data-theme='ae-2023'] .bg-wash .formatted h6 {
    color: var(--primary);
}


/* light */

[data-theme='ae-2023'] .bg-light {
    color: var(--default-text-color);
}
[data-theme='ae-2023'] .bg-light .formatted h1,
[data-theme='ae-2023'] .bg-light .formatted h2,
[data-theme='ae-2023'] .bg-light .formatted h3,
[data-theme='ae-2023'] .bg-light .formatted h4,
[data-theme='ae-2023'] .bg-light .formatted h5,
[data-theme='ae-2023'] .bg-light .formatted h6 {
    color: var(--primary);
}

/* primary */

[data-theme='ae-2023'] .bg-primary,
[data-theme='ae-2023'] .bg-primary .formatted h1,
[data-theme='ae-2023'] .bg-primary .formatted h2,
[data-theme='ae-2023'] .bg-primary .formatted h3,
[data-theme='ae-2023'] .bg-primary .formatted h4,
[data-theme='ae-2023'] .bg-primary .formatted h5,
[data-theme='ae-2023'] .bg-primary .formatted h6 {
    color: white;
}

[data-theme='ae-2023'] .bg-primary .form input[type='submit'] {
    background-color: var(--dark);
    border-color: var(--dark);
    color: white;
}
[data-theme='ae-2023'] .bg-primary .form input[type='submit']:hover {
    background-color: white;
    border-color: white;
    color: var(--dark);
}


/* medium */

[data-theme='ae-2023'] .bg-medium .formatted h1,
[data-theme='ae-2023'] .bg-medium .formatted h2,
[data-theme='ae-2023'] .bg-medium .formatted h3,
[data-theme='ae-2023'] .bg-medium .formatted h4,
[data-theme='ae-2023'] .bg-medium .formatted h5,
[data-theme='ae-2023'] .bg-medium .formatted h6 {
    color: var(--primary);
}


/* theme */

[data-theme='ae-2023'] .bg-dark,
[data-theme='ae-2023'] .bg-dark .formatted h1,
[data-theme='ae-2023'] .bg-dark .formatted h2,
[data-theme='ae-2023'] .bg-dark .formatted h3,
[data-theme='ae-2023'] .bg-dark .formatted h4,
[data-theme='ae-2023'] .bg-dark .formatted h5,
[data-theme='ae-2023'] .bg-dark .formatted h6 {
    color: white;
}

/* [data-theme='ae-2023'] .bg-theme .bg-white,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h1,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h2,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h3,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h4,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h5,
[data-theme='ae-2023'] .bg-theme .bg-white .formatted h6 {
    color: var(--default-text-color-ae);
} */


/* BUTTONS ------------------------------------------------- */


[data-theme='ae-2023'] .button {
	border: 1px solid var(--secondary);
	background-color: var(--secondary);
	color: white;
}

[data-theme='ae-2023'] .button:hover {
	background-color: var(--primary);
	border-color: var(--primary);
	color: white;
}
[data-theme='ae-2023'] .button.inverse:hover {
	color: var(--secondary);
}

[data-theme='ae-2023'] .button.outline {
	border-color: var(--secondary);
}
[data-theme='ae-2023'] .button.outline:hover {
	background-color: var(--secondary);
	border-color: var(--secondary);
	color: white;
}

[data-theme='ae-2023'] .button.outline.inverse {
	color: white;
	background-color: transparent;
	border-color: var(--primary);
}
[data-theme='ae-2023'] .button.outline.inverse:hover {
	background-color: var(--primary);
	color: var(--secondary);
}







/* FORMS ––––––––––––––––––––––––––––––––––––––––––––––––––– */


[data-theme='ae-2023'] .form input[type='submit'] {
	border: 1px solid var(--secondary);
	background-color: var(--secondary);
}

[data-theme='ae-2023'] .form input[type='submit']:hover {
    border: 1px solid var(--primary);
	background-color: var(--primary);
}
